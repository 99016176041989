define("client-base/components/cb-message/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class CbMessage
   * @param {String} message The message to show
   * @param {normal|small|inline|autohide} [variant='normal'] the message can be shown in different variants
   * @param {boolean} [show=true] property for controlling the visibility of the message
   * @param {String} class additional classes
   * @param {String} [type='error'] The type of the message: warning or error
   * @param {Function} onClose method to call, if close button is pressed, close button is only shown if there an onClose method, must be an action
   */
  class CbMessage extends _component.default {
    get show() {
      return this.args.show ?? true;
    }

    get variant() {
      return this.args.variant ?? 'normal';
    }

    get type() {
      return this.args.type ?? 'error';
    }

  }

  _exports.default = CbMessage;
});