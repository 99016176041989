define("client-base/components/cb-message/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
  	{{!-- We duplicate the p-tag, because we don't want to have it if there is no message in the inline form --}}
  	{{#if (or @message (has-block))}}
  		<div ...attributes
  				class="cb-message {{this.type}}
  						msg-{{this.variant}}
  						{{if @class @class}}
  				"
  		>
  			{{#if (has-block)}}
  				{{yield}}
  			{{else if @message}}
  				{{@message}}
  			{{/if}}
  			{{#if @onClose}}
  				<a class="cb-btn-close" href="#" {{on "click" @onClose}} title={{t "cb-message.close"}}><FaIcon @icon="times"/></a>
  			{{/if}}
  		</div>
  	{{/if}}
  {{/if}}
  */
  {
    "id": "RCaCfDQE",
    "block": "[[[41,[30,0,[\"show\"]],[[[41,[28,[37,1],[[30,1],[48,[30,5]]],null],[[[1,\"\\t\\t\"],[11,0],[17,2],[16,0,[29,[\"cb-message \",[30,0,[\"type\"]],\"\\n\\t\\t\\t\\t\\t\\tmsg-\",[30,0,[\"variant\"]],\"\\n\\t\\t\\t\\t\\t\\t\",[52,[30,3],[30,3]],\"\\n\\t\\t\\t\\t\"]]],[12],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"\\t\\t\\t\\t\"],[18,5,null],[1,\"\\n\"]],[]],[[[41,[30,1],[[[1,\"\\t\\t\\t\\t\"],[1,[30,1]],[1,\"\\n\\t\\t\\t\"]],[]],null]],[]]],[41,[30,4],[[[1,\"\\t\\t\\t\\t\"],[11,3],[24,0,\"cb-btn-close\"],[24,6,\"#\"],[16,\"title\",[28,[37,4],[\"cb-message.close\"],null]],[4,[38,5],[\"click\",[30,4]],null],[12],[8,[39,6],null,[[\"@icon\"],[\"times\"]],null],[13],[1,\"\\n\"]],[]],null],[1,\"\\t\\t\"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[\"@message\",\"&attrs\",\"@class\",\"@onClose\",\"&default\"],false,[\"if\",\"or\",\"has-block\",\"yield\",\"t\",\"on\",\"fa-icon\"]]",
    "moduleName": "client-base/components/cb-message/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});